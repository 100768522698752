exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abd-gocmenlik-avukatlari-js": () => import("./../../../src/pages/abd-gocmenlik-avukatlari.js" /* webpackChunkName: "component---src-pages-abd-gocmenlik-avukatlari-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-advertising-disclaimers-js": () => import("./../../../src/pages/advertising-disclaimers.js" /* webpackChunkName: "component---src-pages-advertising-disclaimers-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bulgarian-js": () => import("./../../../src/pages/bulgarian.js" /* webpackChunkName: "component---src-pages-bulgarian-js" */),
  "component---src-pages-business-law-services-js": () => import("./../../../src/pages/business-law-services.js" /* webpackChunkName: "component---src-pages-business-law-services-js" */),
  "component---src-pages-charleston-immigration-lawyer-js": () => import("./../../../src/pages/charleston-immigration-lawyer.js" /* webpackChunkName: "component---src-pages-charleston-immigration-lawyer-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-declaration-js": () => import("./../../../src/pages/cookie-declaration.js" /* webpackChunkName: "component---src-pages-cookie-declaration-js" */),
  "component---src-pages-e-2-visa-js": () => import("./../../../src/pages/e-2-visa.js" /* webpackChunkName: "component---src-pages-e-2-visa-js" */),
  "component---src-pages-eb-5-visa-js": () => import("./../../../src/pages/eb-5-visa.js" /* webpackChunkName: "component---src-pages-eb-5-visa-js" */),
  "component---src-pages-employment-based-visa-immigration-js": () => import("./../../../src/pages/employment-based-visa-immigration.js" /* webpackChunkName: "component---src-pages-employment-based-visa-immigration-js" */),
  "component---src-pages-family-based-green-card-immigration-js": () => import("./../../../src/pages/family-based-green-card-immigration.js" /* webpackChunkName: "component---src-pages-family-based-green-card-immigration-js" */),
  "component---src-pages-general-counsel-services-js": () => import("./../../../src/pages/general-counsel-services.js" /* webpackChunkName: "component---src-pages-general-counsel-services-js" */),
  "component---src-pages-german-js": () => import("./../../../src/pages/german.js" /* webpackChunkName: "component---src-pages-german-js" */),
  "component---src-pages-hungarian-js": () => import("./../../../src/pages/hungarian.js" /* webpackChunkName: "component---src-pages-hungarian-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individual-immigration-services-js": () => import("./../../../src/pages/individual-immigration-services.js" /* webpackChunkName: "component---src-pages-individual-immigration-services-js" */),
  "component---src-pages-intellectual-property-legal-services-js": () => import("./../../../src/pages/intellectual-property-legal-services.js" /* webpackChunkName: "component---src-pages-intellectual-property-legal-services-js" */),
  "component---src-pages-investor-visa-usa-immigration-js": () => import("./../../../src/pages/investor-visa-usa-immigration.js" /* webpackChunkName: "component---src-pages-investor-visa-usa-immigration-js" */),
  "component---src-pages-l-1-visa-js": () => import("./../../../src/pages/l-1-visa.js" /* webpackChunkName: "component---src-pages-l-1-visa-js" */),
  "component---src-pages-new-york-immigration-lawyer-downtown-js": () => import("./../../../src/pages/new-york-immigration-lawyer-downtown.js" /* webpackChunkName: "component---src-pages-new-york-immigration-lawyer-downtown-js" */),
  "component---src-pages-new-york-immigration-lawyer-js": () => import("./../../../src/pages/new-york-immigration-lawyer.js" /* webpackChunkName: "component---src-pages-new-york-immigration-lawyer-js" */),
  "component---src-pages-offices-js": () => import("./../../../src/pages/offices.js" /* webpackChunkName: "component---src-pages-offices-js" */),
  "component---src-pages-our-clients-js": () => import("./../../../src/pages/our-clients.js" /* webpackChunkName: "component---src-pages-our-clients-js" */),
  "component---src-pages-private-client-services-js": () => import("./../../../src/pages/private-client-services.js" /* webpackChunkName: "component---src-pages-private-client-services-js" */),
  "component---src-pages-russian-js": () => import("./../../../src/pages/russian.js" /* webpackChunkName: "component---src-pages-russian-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-spanish-js": () => import("./../../../src/pages/spanish.js" /* webpackChunkName: "component---src-pages-spanish-js" */),
  "component---src-pages-startup-lawyer-for-business-startups-js": () => import("./../../../src/pages/startup-lawyer-for-business-startups.js" /* webpackChunkName: "component---src-pages-startup-lawyer-for-business-startups-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-success-stories-js": () => import("./../../../src/templates/success-stories.js" /* webpackChunkName: "component---src-templates-success-stories-js" */)
}

